import './App.css';
import UnderConstruction from './src/UnderConstruction';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "animate.css";

function App() {
  return (
    <div className="App">
      <UnderConstruction />
    </div>
  );
}

export default App;
