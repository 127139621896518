import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import "animate.css";


const UnderConstruction = () => {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const[email , setEmail ] = useState('')
    const [isLoading, setIsLoading] = useState(false); // Add loading state


    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const templateParams = {
          email: email, // Ensure `email` is a state or variable storing the user input.
        };
      
        emailjs
          .send("service_62yvdur", "template_yc2z2hb", templateParams, "jRN3Qp0mMYEvkhNLm")
          .then(
            () => {
              console.log("SUCCESS!");
              setIsSubmitted(true); // Update the state to show success message
              setIsLoading(false); // Stop loading
            },
            (error) => {
              console.error("FAILED...", error); // Log the error for debugging
              setIsLoading(false); // Stop loading
            }
          );
      };


  return (
    <div>
    <header>
      <h1>{`{ We're Coming }`}</h1>
      <h3>If you leave us your address we can contact you</h3>
    </header>

    <section className="container">
      <div className={`wrapBox ${!isSubmitted ? "animate__rollIn" : ""}`}>
        <div className="box">
          {isSubmitted ? (
            <div className={`thanks ${isSubmitted ? "animate__rollIn" : ""}`}>
              <p>THANK YOU!</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email">Your best email address:</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder=""
                  required
                />
              </div>
              <div>
              <button id="send" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <span className="spinner">
                        <i className="fa fa-spinner fa-spin"></i> Sending...
                      </span>
                    ) : (
                      <>
                        SUBMIT <i className="fa fa-paper-plane"></i>
                      </>
                    )}
                  </button>
              </div>
            </form>
          )}
          <div className="social">
            <ul>
              <li>
                <a target="_blank" href='https://www.facebook.com/profile.php?id=61569347933200&mibextid=JRoKGi'><i className="fa-brands fa-facebook-f"></i></a>
              </li>
              <li>
                <a target="_blank" href='https://www.instagram.com/catapultmediawork?igsh=MXJsb2FkMzl2Yjl6Mg=='> <i className="fa-brands fa-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <footer></footer>
  </div>
  )
}

export default UnderConstruction